import APIService from "./APIService";

export default class EmpresaService {  
  api = new APIService();

  async all() {
    try {
      const response = await this.api.send("get", "empresa");
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async todos() {
    try {
      const response = await this.api.send("get", "empresa/all");
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async get(CodEmp) {
    try {
      const response = await this.api.send("get", "empresa/" + CodEmp);
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async getDefault() {
    try {
      const response = await this.api.send("get", "empresa-default");
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async change(CodEmp) {
    try {
      const response = await this.api.send("get", "empresa-change/" + CodEmp);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async store(data) {
    try{
      const response = await this.api.send("post", "empresa", data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async update(data) {
    try{
      const response = await this.api.send("put", "empresa/" + data.CODEMP, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async delete(data) {
    try{
      const response = await this.api.send("delete", "empresa/" + data.CODEMP);
      return response;
    }
    catch (e) {
      return false;
    }
  }
}

