<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits, watch } from 'vue';

import ContadorService from '@/service/ContadorService';

const props = defineProps(
  [
    "classes",
    "multiple",
    "modelValue",
    'update:modelValue',
    'label',
    'disabled',
    "selected",
    'codemp'
  ]
);
const emit = defineEmits(['update:modelValue']);

const service = new ContadorService();

const listaContador = ref([]);
const contador = ref();
const label = ref(props.label ?? 'NOMCTA');
const codemp = ref(props.codemp ?? null);
const loading = ref(false);
const disabled = ref(props.disabled);

const filtering = (evt) => {
  load(evt.value);
}

const load = async (term) => {
  listaContador.value = [];

  loading.value = true;

  service.api.abort();

  let opts = {
    ce: codemp.value,
    filtro: term
  };
  await new Promise((resolve)=>{
    service.searchContadorBox(opts)
    .then(response => {
      if (Array.isArray(response)) {
        const data = [];
        response.map(
          (entry) => data.push(
            {
              label : entry[label.value] ?? entry.NOMCTA,
              value : entry.CODCTA,
            }
          )
        );

        listaContador.value = data;
        // console.log(data);
      }
      // console.log(response);
    })
    .finally(() => {
      loading.value = false;
      resolve();
    });
  })
}

watch(codemp, (novoValor, velhoValor) => {
  if(velhoValor != novoValor){
    listaContador.value = [];
  }
})

watch(contador, (novoValor, velhoValor) => {
  // console.log("contador");
})

onUpdated(() => {
  // console.log("props",props);
  // if (props.modelValue) {
    // contador.value = props.modelValue;
  // }

  if (props.label) {
    label.value = props.label;
  }

  if (props.disabled) {
    disabled.value = props.disabled;
  }

  if (props.codemp) {
    codemp.value = props.codemp;
  }
});

onMounted(async () => {
  await load();

  if(props.selected){
    if(props.selected.value){
      //Carrega os dados de acordo com o codigo do contador
      await load(props.selected.value);
      // Busca o contador na lista de acordo com o código
      let selecionado = listaContador.value.find(item => item.value == props.selected.value);
      let cta = {};
      // Caso ache um contador preenche os dados
      if(selecionado){
        for (const key in selecionado) {
          cta[key] = selecionado[key]
        }
        contador.value = cta;
      }
    }
  }
})

</script>

<template>
  {{ listaContador.value }}
  <Dropdown
    v-model="contador"
    :disabled="disabled"
    :class="classes"
    :options="listaContador"
    :filter="true"
    :showClear="true"
    :loading="loading"
    :filterFields="['label','value']"
    class="flex"
    optionLabel="label"
    placeholder="Selecione"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    @filter="filtering"
    @change="(event) => emit('update:modelValue', event.value)"
    v-if="!props.multiple"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>

  <MultiSelect
    placeholder="Selecionoe"
    v-model="contador"
    :options="listaContador"
    :loading="loading"
    :filter="true"
    :showClear="true"
    @filter="filtering"
    @change="(event) => emit('update:modelValue', event.value)"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    v-if="props.multiple"
  >
    <template #option="slotProps">
      <div class="flex align-items-center">
          <div v-if="slotProps.option">{{ slotProps.option.label }}</div>
      </div>
    </template>
    <template #header="slotProps">
      <div class="flex align-items-center" v-if="slotProps.options">
        <div></div>
      </div>
      <div v-else>
        <div>{{slotProps.placeholder}}</div>
      </div>
    </template>
  </MultiSelect>
</template>