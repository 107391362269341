<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits, onBeforeMount } from 'vue';
import moment from 'moment';

const props = defineProps(
  [
    "classes",
    "format",
    "disabled",
    "selected",
    "idInput",
    "nameInput"
  ]
);

const emit = defineEmits(['update:modelValue']);
const objeto = ref();
const disabled = ref(props.disabled);
const format = ref(props.format);
const mask = ref("99/99/9999");
const classes = ref(props.classes)

function retorna(value) {
  emit('update:modelValue', value)
}
function formata(value) {
  if (value.toString().includes("/")) {
    value = value.split("/").reverse().join("-");
  }
  // console.log("value", value);
  // console.log("moment(value).format('YYYY')", moment(value).format("YYYY"));
  // console.log(format.value);
  let retorno;

  switch (format.value) {
    case "A":
      // mask.value = "9999";
      retorno = moment(value).format("YYYY");
      break;
    case "M":
      // mask.value = "99";
      retorno = moment(value).format("MM");
      break;
    case "D":
      // mask.value = "99";
      retorno = moment(value).format("DD");
      break;
    case "DM":
      // mask.value = "99/99";
      retorno = moment(value).format("DD/MM");
      break;
    case "MA":
      // mask.value = "99/9999"
      retorno = moment(value).format("MM/YYYY");
      break;
    case "Ma":
      // mask.value = "99/99";
      retorno = moment(value).format("MM/YY");
      break;
    case "DMa":
      // mask.value = "99/99";
      retorno = moment(value).format("DD/MM/YY");
      break;
    default:
      // mask.value = "99/99/9999"
      retorno = moment(value).format("DD/MM/YYYY");
      break;
  }
  // console.log(retorno);

  return retorno;
}
onBeforeMount(() => {
  if (props.format && props.format != undefined) {
    format.value = props.format;
  }

  switch (format.value) {
    case "A":
      mask.value = "9999";
      break;
    case "M":
      mask.value = "99";
      break;
    case "D":
      mask.value = "99";
      break;
    case "DM":
      mask.value = "99/99";
      break;
    case "MA":
      mask.value = "99/9999"
      break;
    case "Ma":
      mask.value = "99/99";
      break;
    case "DMa":
      mask.value = "99/99/99";
      break;
    default:
      mask.value = "99/99/9999"
      break;
  }
})

onMounted(() => {
  if (props.selected != undefined) {
    objeto.value = props.selected.replaceAll("/", "");
  }
})

onUpdated(() => {
  if (props.selected) {
    objeto.value = props.selected;
  }

  if (props.disabled) {
    disabled.value = props.disabled;
  }

  if (props.classes) {
    classes.value = props.classes;
  }
});
</script>

<template>
  <div>
    <span class="p-input-icon-right">
      <i class="pi pi-calendar" />
      <InputMask
        :id="idInput"
        :name="nameInput"
        :mask="mask"
        v-model="objeto"
        :disabled="disabled"
        :class="classes"
        @update:modelValue="retorna($event)" />
    </span>
  </div>
</template>