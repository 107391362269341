<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits } from 'vue';

const props = defineProps(
  [
    "classes",
    "modelValue",
    "update:modelValue",
    "disabled",
    "selected",
    "tipo",
  ]
);

const emit = defineEmits(['update:modelValue']);
const listaDias = ref([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]);
const listaMeses = ref([1,2,3,4,5,6,7,8,9,10,11,12]);
const lista = ref([]);
const objeto = ref();
const disabled = ref(props.disabled);

onUpdated(() => {

  if (props.disabled) {
    disabled.value = props.disabled;
  }

});

onMounted(() => {
  if(props?.tipo){
    lista.value = props.tipo == "D" ? listaDias.value : (props.tipo == "M" ? listaMeses.value : []);
  }
  if(props.selected != undefined && props.selected){
    objeto.value = props.selected;
  }
})
</script>

<template>
  <Dropdown
    v-model="objeto"
    :disabled="disabled"
    :class="classes"
    :options="lista"
    :showClear="true"
    class="flex"
    placeholder="Selecione"
    @change="(event) => emit('update:modelValue', event.value)">
    <template #value="slotProps">
      <div v-if="slotProps.value && slotProps.value">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>
</template>