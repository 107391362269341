<template>
  {{ dynamicText }}
</template>

<script>
import TraducaoService from '../service/TraducaoService';
import EventBus from '../AppEventBus';
import { provide, inject } from 'vue';

export default {
  props : ['text'],
  data : function() {
    return {
      dynamicText : this.text,
      termos1: null
    };
  },
  mounted : async function () {
    //const traducaoService = new TraducaoService();
    /*
    if(this.$root.terms == undefined){
      let traslate_object = localStorage.getItem("traslate_object");

      if (traslate_object) {
        this.terms = this.$root.terms = JSON.parse(traslate_object);
      }
      else {
        const response = await traducaoService.once();

        localStorage.setItem("traslate_object", JSON.stringify(response));

        this.terms = (response);
        if(response){
          this.$root.terms = (response)
        }
      }
    }
    else{
      this.terms = this.$root.terms;
    }
    */

    this.terms = this.$root.terms ?? [];
    
    this.translate();
  },
  methods : {
    translate() {
      this.dynamicText = this.terms[this.text] ?? this.text;
    }
  }
}
</script>