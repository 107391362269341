<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits, watch } from 'vue';

import Service from '@/service/DefContribService';

const props = defineProps(
  [
    "classes",
    "multiple",
    "modelValue",
    "update:modelValue",
    "label",
    "disabled",
    "codgdc",
    "selected"
  ]
);
const emit = defineEmits(['update:modelValue']);

const service = new Service();

const lista = ref([]);
const objeto = ref();
const codgdc = ref(props.codgdc ?? null);
const loading = ref(false);
const disabled = ref(props.disabled);

const filtering = (evt) => {
  load(evt.value);
}

const load = async (term) => {
  lista.value = [];

  loading.value = true;

  service.api.abort();

  let opts = {
    ce: codgdc.value,
    filtro: term
  };
  new Promise((resolve)=>{
    service.searchDefContribBox(opts)
    .then(response => {
      if (Array.isArray(response)) {
        const data = [];
        response.map(
          (entry) => data.push(
            {
              label : entry.DESDFC,
              value : entry.CODDFC,
            }
          )
        );

        lista.value = data;
      }
    })
    .finally(() => {
      loading.value = false
      resolve();
    });
  })

}

watch(codgdc, (novoValor, velhoValor) => {
  if(velhoValor != novoValor){
    lista.value = [];
  }
})

onUpdated(() => {
  if(props.modelValue && props.modelValue.value){
    objeto.value = props.modelValue;
  }

  if (props.disabled) {
    disabled.value = props.disabled;
  }

  if (props.codgdc) {
    codgdc.value = props.codgdc;
  }
});

onMounted(() => {
  if (props.selected != undefined && props.selected.value) {
    let selecionado = lista.value.find(item => item.value == props.selected.value);
    let obj = {};
    for (const key in selecionado) {
      obj[key] = selecionado[key]
    }
    objeto.value = obj;
  }
})

</script>

<template>
  {{ lista.value }}
  <Dropdown
    v-model="objeto"
    :disabled="disabled"
    :class="classes"
    :options="lista"
    :filter="true"
    :showClear="true"
    :loading="loading"
    :filterFields="['label','value']"
    class="flex"
    optionLabel="label"
    placeholder="Selecione"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    @filter="filtering"
    @change="(event) => emit('update:modelValue', event.value)"
    v-if="!props.multiple"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>

  <MultiSelect
    placeholder="Selecionoe"
    v-model="objeto"
    :options="lista"
    :loading="loading"
    :filter="true"
    :showClear="true"
    @filter="filtering"
    @change="(event) => emit('update:modelValue', event.value)"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    v-if="props.multiple"
  >
    <template #option="slotProps">
      <div class="flex align-items-center">
          <div v-if="slotProps.option">{{ slotProps.option.label }}</div>
      </div>
    </template>
    <template #header="slotProps">
      <div class="flex align-items-center" v-if="slotProps.options">
        <div></div>
      </div>
      <div v-else>
        <div>{{slotProps.placeholder}}</div>
      </div>
    </template>
  </MultiSelect>
</template>