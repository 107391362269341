<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps(["texto"]);

const tituloModal = ref(props?.texto);

</script>

<template>
  <div>
    <h5 class="mb-0">
      <b>
        <Translate :text="tituloModal" />
      </b>
    </h5>
  </div>
</template>