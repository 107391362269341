<script setup>
import { ref, defineProps} from 'vue';

const props = defineProps(["texto", "for", "obrigatorio", "sufix"]);

const textoLabel = ref(props?.texto);
const forLabel = ref(props?.for);
const obrigatorio = ref(props?.obrigatorio != undefined ? true : false);

</script>

<template>
  <label :for="forLabel"><Translate :text="textoLabel" /><span v-if="sufix">{{ props.sufix }}</span><span v-if="obrigatorio == true">*</span></label>
</template>