import APIService from "./APIService";

export default class ContatoService extends APIService {
  async all(CodEnt, options) {
    try {
      const response = await this.send("get", "entidade/"+CodEnt+"/contato" + this.serverSideOptionsFormat(options));
      return response;
    }
    catch (e) {
      console.log(e)
      return false;
    }
  }

  async todos(CodEnt, options) {
    try {
      const response = await this.send("get", "contato/todos/" + CodEnt + this.serverSideOptionsFormat(options),

      );
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async search(src, options) {
    try {
      const response = await this.send("get", "contato/search/" + src + this.serverSideOptionsFormat(options));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchFromEntidade(CodEnt, src, options) {
    try {
      const response = await this.send("get", "contato/search/" + CodEnt + "/" + src + this.serverSideOptionsFormat(options));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchComEntidade(CodEnt, src, options) {
    try {
      const response = await this.send("get", "contato/searchComEntidade/" + CodEnt + "/" + src + this.serverSideOptionsFormat(options));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchContatoBox(src) {
      try {
        const response = await this.send("get", "contato/eb/" +`?filtro=${src.filtro}${src.entidade != null ?"&entidade=" + src.entidade : ''}`);
        return response;
      }
      catch (e) {
        return false;
      }
    }

  async searchFromEntidade2(options) {
    try {
      const response = await this.send("get", "contato/search/" + options.codEnt, options);
      return response;
    }
    catch (e) {
      return false;
    }
  }


  async get(CodEnt, CodCon) {
    try {
      const response = await this.send("get", "entidade/" + CodEnt + "/contato/" + CodCon);
      return response;
    }
    catch (e) {
      return false;
    }
  }


  async store(data) {
    try {
      const response = await this.send("post", "contato/store", data);
      if(!response){
        return this.api.result;
      }
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async update(data) {
    try {
      return await this.send("put", "entidade/" + data.CODENT + "/contato/" + data.CODCON, data);
    }
    catch (e) {
      return false;
    }
  }

  async delete(data) {
    try {
      return await this.send("delete", "entidade/"+data.CODENT+"/contato/" + data.CODCON, data);
    }
    catch (e) {
      return false;
    }
  }

  async busca(codent, codcon) {
    try {
      const response = await this.send("get", "entidade/" + codent + "/contato/" + codcon);
      return response;
    }
    catch (e) {
      return false;
    }
  }
}