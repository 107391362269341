import UsuarioService from '../service/administracao/UsuarioService';

export default class UserController {
  async me() {
    const me = await (new UsuarioService()).me();

    localStorage.setItem("user", JSON.stringify(me));

    return me;
  }

  get() {
    const data = localStorage.getItem("user");
    if (data) {
      return JSON.parse(data)
    }
    return null;
  }
}