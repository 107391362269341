<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits, watch, reactive } from 'vue';

import VirtualScroller from 'primevue/virtualscroller';
import OverlayPanel from 'primevue/overlaypanel';
import TipoDocumentoService from '@/service/financeiro/TipoDocumentoService';

const props = defineProps(
  [
    "classes",
    "modelValue",
    'update:modelValue',
    'label',
    'disabled'
  ]
);

const emit = defineEmits(['update:modelValue']);

const service = new TipoDocumentoService();

const lista = ref([]);
const objeto = ref();
const label = ref(props.label ?? 'NOMENT');
const loading = ref(false);
const disabled = ref(props.disabled);

const load = () => {
  lista.value = [];

  loading.value = true;

  service.all()
  .then(response => {
    if (Array.isArray(response)) {
      const data = [];

      response.map(
        (entry) =>
        {
          if(props.modelValue){
            objeto.value = {
              label : entry[label.value] ?? entry.DESTIPDOC,
              value : entry.CODTIPDOC,
            }
          }

          data.push(
            {
              label : entry[label.value] ?? entry.DESTIPDOC,
              value : entry.CODTIPDOC,
            }
          )
        }
      );
      lista.value = data;
    }
  })
  .finally(() => {
    loading.value = false
  });
}

onUpdated(() => {

  objeto.value = props.modelValue;

  if (props.label) {
    label.value = props.label;
  }

  if (props.disabled) {
    disabled.value = props.disabled;
  }
});

onMounted(() => {
  load();
})
</script>

<template>
  <Dropdown
    v-model="objeto"
    :disabled="disabled"
    :class="classes"
    :options="lista"
    :filter="true"
    :showClear="true"
    :loading="loading"
    :filterFields="['label','value']"
    class="flex"
    optionLabel="label"
    placeholder="Selecione"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    @change="(event) => emit('update:modelValue', event.value)">
    <template #value="slotProps">
      <div v-if="slotProps.value">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>
</template>