<script setup>
import {defineProps} from "vue";

const props = defineProps({
  menssagem: {
    type: String,
    required: false,
    default: "Este campo é obrigatório."
  }
})

</script>

<template>
  <div>
    <small class="p-error">
      <Translate :text="props.menssagem" />
    </small>
  </div>
</template>