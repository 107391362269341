<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits } from 'vue';

const props = defineProps(
  [
    "classes",
    "modelValue",
    "update:modelValue",
    "label",
    "disabled",
    "selected"
  ]
);

const emit = defineEmits(['update:modelValue']);

const lista = ref([
  { label: "Pré-pago", value: "1" },
  { label: "Pós-pago", value: "2" }
]);
const objeto = ref();
const disabled = ref(props.disabled);

onUpdated(() => {

  if (props.disabled) {
    disabled.value = props.disabled;
  }

});

onMounted(() => {
  if(props.selected != undefined && props.selected.value){
    let selecionado = lista.value.find(item => item.value == props.selected.value);
    let obj = {};
    for (const key in selecionado) {
      obj[key] = selecionado[key]
    }
    objeto.value = obj;
  }
})
</script>

<template>
  <Dropdown
    v-model="objeto"
    :disabled="disabled"
    :class="classes"
    :options="lista"
    :showClear="true"
    class="flex"
    optionLabel="label"
    placeholder="Selecione"
    @change="(event) => emit('update:modelValue', event.value)">
    <template #value="slotProps">
      <div v-if="slotProps.value && slotProps.value.value">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>
</template>