<script setup>
import { onBeforeMount, ref, defineProps, defineEmits, onUpdated } from 'vue';
import EstadoService from '../../service/EstadoService';

const props = defineProps(
  [
    "classes",
    "modelValue",
    "tipoRetorno",
    "update:modelValue",
    "disabled",
    "selected"
  ]
);
onUpdated(() => {
  if (props.disabled) {
    disabled.value = props.disabled;
  }
});
const emit = defineEmits(['update:modelValue']);

const service = new EstadoService();
const lista = ref();
const objeto = ref();
const disabled = ref(props.disabled);
const tipoRetorno = ref("completo");

onBeforeMount(async()=>{
  if(props.tipoRetorno){
    tipoRetorno.value = props.tipoRetorno == "completo" ? "completo" : "sigla";
  }

  await service.all().then((response) => {
    response.map((item) => {
      lista.value.push({
        value: item.CODEST,
        label: item.DESEST,
      })
    })
  })

  if(props.selected != undefined && props.selected.value){
    let selecionado = lista.value.find(item => item.value == props.selected.value);
    let obj = {};
    for (const key in selecionado) {
      obj[key] = selecionado[key]
    }
    objeto.value = obj;
  }
})

function retornaValor(ev) {
  if(props.tipoRetorno){
    emit('update:modelValue', ev.value)
  }
}

</script>

<template>
  <div>
    <Dropdown
      v-model="objeto"
      :disabled="disabled"
      :class="classes"
      :options="lista"
      :filter="true"
      :showClear="true"
      :loading="loading"
      :filterFields="['label','value']"
      class="flex"
      optionLabel="label"
      placeholder="Selecione"
      emptyFilterMessage="Nenhum registro encontrado"
      emptyMessage="Nenhuma opção disponível"
      @change="(event) => emit('update:modelValue', event.value)">
      <template #value="slotProps">
        <div v-if="slotProps.value">
          <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
        </div>
        <span v-else>
          {{slotProps.placeholder}}
        </span>
      </template>
    </Dropdown>
  </div>
</template>