<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits } from 'vue';

import TipoDissidioService from '@/service/TipoDissidioService';

const props = defineProps(
  [
    "classes",
    "modelValue",
    'update:modelValue',
    'label',
    'disabled',
    "selected",
  ]
);
const emit = defineEmits(['update:modelValue']);

const service = new TipoDissidioService();

const listaTipoDissidio = ref([]);
const tipoDissidio = ref();
const label = ref(props.label ?? 'DESTDI');
const loading = ref(false);
const disabled = ref(props.disabled);

const load = async (term) => {
  listaTipoDissidio.value = [];

  loading.value = true;

  service.api.abort();

  await new Promise((resolve) => {
    service.all()
    .then(response => {
      if (Array.isArray(response)) {
        const data = [];
        response.map(
          (entry) => data.push(
            {
              label : entry[label.value] ?? entry.DESTDI,
              value : entry.CODTDI,
            }
          )
        );
        listaTipoDissidio.value = data;
      }
    })
    .finally(() => {
      loading.value = false;
      resolve();
    });
  })
}

onUpdated(() => {
  if (props.label) {
    label.value = props.label;
  }

  if (props.disabled) {
    disabled.value = props.disabled;
  }

});

onMounted(async () => {
  console.log("tipo dissidio",props?.selected);
  if(props.selected){
    //Carrega os dados de acordo com o codigo do tipoDissidio
    await load();
    // Busca o tipoDissidio na lista de acordo com o código
    let selecionado = listaTipoDissidio.value.find(item => item.value == props.selected);
    let cta = {};
    // Caso ache um tipoDissidio preenche os dados
    if(selecionado){
      for (const key in selecionado) {
        cta[key] = selecionado[key]
      }
      tipoDissidio.value = cta;
    }
  }else{
    await load();
  }
})

</script>

<template>
  {{ listaTipoDissidio.value }}
  <Dropdown
    v-model="tipoDissidio"
    :disabled="disabled"
    :class="classes"
    :options="listaTipoDissidio"
    :filter="true"
    :showClear="true"
    :loading="loading"
    :filterFields="['label','value']"
    class="flex"
    optionLabel="label"
    placeholder="Selecione"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    @change="(event) => emit('update:modelValue', event?.value?.value)"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>
</template>