import LoginService from '../service/LoginService';
import EventBus from '../AppEventBus';
import UsuarioService from '../service/administracao/UsuarioService';
import UserController from '../controllers/user';
import { inject } from 'vue';

export default {
  inject : ['user'],
  data: function () {
    return {
      isLogged : false,
      user_data : null,
      loginService : null,
      user : null,
    };
  },
  created: function () {
    this.loginService = new LoginService();
  },
  methods : {
    async loginStart() {
      const result = await this.loginService.start();

      this.$csrfCookie.checked = true;
    },
    async loginSend(email, password) {
      return await this.loginService.login(email, password);
    },
    async loginCheck () {
      const result = await this.loginService.check();

      if (result[0]) {
        this.isLogged = true;
        this.user_data = result[1];

        const me = await (new UserController()).me();

        this.user = this.$root.user = me;

        EventBus.emit(
          "user-logged", 
          {
            user : this.user
          }
        );
      }

      return result;
    },
    async loginCheckAuth0 (sub) {
      const result = await this.loginService.checkAuth0(sub);

      if (result[0]) {
        this.isLogged = true;
        this.user_data = result[1];
        this.$root.user = this.user_data;
        EventBus.emit(
          "user-logged", 
          {
            user : this.user_data
          }
        );
      }

      return result;
    },

    async connectAuth0(sub) {
      return await this.loginService.connectAuth0(sub);
    },
    onLogged() {},
    async passwordRecovery(email) {
      return await this.loginService.recovery(email);
    }
  }
}
