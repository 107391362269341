import APIService from "./APIService";

export default class ContadorService {
  api = new APIService();

  async all() {
    try {
      const response = await this.api.send("get", "contador");

      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchContadorBox(src) {
      try {
        const response = await this.api.send("get", "contador/cb" + `?filtro=${src.filtro}${src.ce != null ?"&ce=" + src.ce : ''}`);
        // console.log("response",response);
        return response;
      }
      catch (e) {
        return false;
      }
    }

  async get(CodEnt) {
    try {
      const response = await this.api.send("get", "contador/" + CodEnt);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async store(data) {
    try {
      const response = await this.api.send("post", "contador", data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async update(data) {
    try {
      var response = null;
      if (data.CODENT) {
        response = await this.api.send("put", "contador/" + data.CODENT, data);
      }
      else {
        response = await this.api.send("post", "contador", data);
      }

      return response;
    }
    catch (e) {
      return false;
    }
  }

  async delete(CodEnt) {
    try {
      const response = await this.api.send("delete", "contador/" + CodEnt);
      return response;
    }
    catch (e) {
      return false;
    }
  }
}