<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps(["texto"]);

const nomeTela = ref(props?.texto);

</script>

<template>
  <div>
    <h4><Translate :text="nomeTela" /></h4>
  </div>
</template>