<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits, watch, reactive } from 'vue';

import VirtualScroller from 'primevue/virtualscroller';
import OverlayPanel from 'primevue/overlaypanel';
import GrupoDefContribService from '@/service/GrupoDefContribService';

const props = defineProps(
  [
    "classes",
    "modelValue",
    "update:modelValue",
    "disabled",
    "selected"
  ]
);

const emit = defineEmits(['update:modelValue']);

const service = new GrupoDefContribService();

const lista = ref([]);
const objeto = ref();
const loading = ref(false);
const disabled = ref(props.disabled);

onUpdated(() => {
  if (props.disabled) {
    disabled.value = props.disabled;
  }
});

onMounted(() => {
  load();
})

const load = async () => {
  lista.value = [];

  loading.value = true;

  service.all()
  .then(response => {
    if (Array.isArray(response)) {
      const data = [];

      response.map(
        (entry) =>
        {
          data.push(
            {
              label : entry.DESGDC,
              value : entry.CODGDC,
            }
          )
        }
      );

      lista.value = data;
    }
  })
  .finally(() => {
    loading.value = false
    if(props.selected != undefined && props.selected.value){
      let selecionado = lista.value.find(item => item.value == props.selected.value);
      let obj = {};
      for (const key in selecionado) {
        obj[key] = selecionado[key]
      }
      console.log("selecionado",selecionado)
      objeto.value = obj;
    }
  });
}

</script>

<template>
  <Dropdown
    v-model="objeto"
    :disabled="disabled"
    :class="classes"
    :options="lista"
    :showClear="true"
    :loading="loading"
    :filterFields="['label','value']"
    class="flex"
    optionLabel="label"
    placeholder="Selecione"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    @change="(event) => emit('update:modelValue', event.value)">
    <template #value="slotProps">
      <div v-if="slotProps.value && slotProps.value.label">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>
</template>